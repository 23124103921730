import api from "./Axios";



export const login = async (username, password) => {
    try {
      const { data } = await api.post("/auth/login", { username, password });
  
      return { accessToken: data.token,
         user: {
            username: data.username,
            nickname: data.nickname,
            email: data.email,
             },
          }; 
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };


// 회원가입 함수
export const signup = async (userData) => {
  try {
    const response = await api.post("/auth/signup", userData);
    return response.data;
  } catch (error) {
    console.error("Signup failed:", error);
    throw error;
  }
};

// 로그아웃 함수
export const logout = async () => {
  try {
    await api.post("/auth/logout"); // 서버에서 RefreshToken 삭제
  } catch (error) {
    console.error("Logout failed:", error);
  }
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userInfo");
};
