// src/components/mainPage/MainPage.js
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import "./MainPage.css";
import "./Page.css";

function KnowledgePage() {
  const { userInfo } = useContext(AuthContext);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch("https://eb.hongfolio.shop/api/posts/category/knowledge", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userInfo?.token}`, // JWT 토큰 추가
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      }
    };
  
    fetchPosts();
  }, [userInfo]);

  return (
    <div className="main-page">
      <main className="post-section">
        <h2>지식 정리</h2>
        {posts.length > 0 ? (
          <div className="post-list">
            <ul>
              {posts.map((post) => (
                <li key={post.id}>
                  {/* 제목 클릭 시 해당 게시물 상세 페이지로 이동 */}
                  <Link to={`/posts/${post.id}`}>{post.title}</Link>
                </li>
          ))}
        </ul>
          </div>
        ) : (
          <p>게시물이 없습니다.</p>
        )}
        <div>
          <button onClick={() => navigate("/createpost", {
            state: { category: "knowledge" }
          })}>글 작성하기</button>
        </div>
      </main>
    </div>
  );
}

export default KnowledgePage;
