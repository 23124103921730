import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import "./PostDetail.css";

function PostDetail() {
  const { id } = useParams(); // 경로에서 게시물 ID 가져오기
  const [post, setPost] = useState(null);
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await axios.get(`https://eb.hongfolio.shop/api/posts/id/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setPost(response.data);
      } catch (error) {
        console.error("Failed to fetch post:", error);

        // Access Token이 만료된 경우 처리
        if (error.response && error.response.status === 401) {
          await handleTokenRefresh();
          fetchPost(); // 토큰 갱신 후 다시 호출
        }
      }
    };

    fetchPost();
  }, [id]);

  const handleTokenRefresh = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        alert("로그인이 필요합니다.");
        navigate("/login");
        return;
      }

      const response = await axios.post("https://eb.hongfolio.shop/api/auth/refresh", {
        refreshToken,
      });

      const { accessToken, refreshToken: newRefreshToken } = response.data;
      localStorage.setItem("accessToken", accessToken);
      if (newRefreshToken) {
        localStorage.setItem("refreshToken", newRefreshToken);
      }
    } catch (error) {
      console.error("토큰 갱신 실패:", error);
      alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      navigate("/login");
    }
  };

  const handleDelete = async () => {
    if (!window.confirm("정말 삭제하시겠습니까?")) return;

    try {
      const accessToken = localStorage.getItem("accessToken");
      await axios.delete(`https://eb.hongfolio.shop/api/posts/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      alert("게시글이 삭제되었습니다.");
      navigate("/");
    } catch (error) {
      console.error("게시글 삭제 중 오류 발생:", error);

      if (error.response && error.response.status === 401) {
        await handleTokenRefresh();
        handleDelete(); // 토큰 갱신 후 다시 호출
      } else {
        alert("게시글 삭제에 실패했습니다.");
      }
    }
  };

  const handleEdit = () => {
    navigate(`/edit/${id}`);
  };

  if (!post) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ maxWidth: "800px", margin: "50px auto", padding: "20px", border: "1px solid #ddd", borderRadius: "8px", backgroundColor: "#f9f9f9" }}>
      <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "10px" }}>{post.title}</h2>
      <div style={{ marginTop: "20px" }}>
        <p style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: post.content }}></p>
        {post.imageUrl && (
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <img src={post.imageUrl} alt="첨부 이미지" style={{ maxWidth: "100%", borderRadius: "8px" }} />
          </div>
        )}
      </div>
      <p style={{ marginTop: "20px", color: "#666" }}>작성자: <strong>{post.author}</strong></p>
      {userInfo && userInfo.username === post.author && (
        <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
          <button
            onClick={handleEdit}
            style={{ padding: "10px 20px", border: "none", backgroundColor: "#007bff", color: "#fff", borderRadius: "5px", cursor: "pointer" }}
          >
            게시글 수정
          </button>
          <button
            onClick={handleDelete}
            style={{ padding: "10px 20px", border: "none", backgroundColor: "#dc3545", color: "#fff", borderRadius: "5px", cursor: "pointer" }}
          >
            게시글 삭제
          </button>
        </div>
      )}
    </div>
  );
}

export default PostDetail;
