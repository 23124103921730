import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { signup } from "../api/AuthApi";
import { AuthContext } from "../context/AuthContext";
import "./SignupPage.css";

function SignupPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const { handleLogin } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    try {
      const data = await signup({ username, password, nickname, email, phoneNumber });
      const user = { username: data.username, nickname: data.nickname, email: data.email, phoneNumber: data.phonenumber };
      handleLogin(user);
      alert("회원가입 성공!");
      navigate("/");
    } catch (error) {
      console.error("Signup error:", error);
      alert("회원가입 실패, 다시 확인하세요.");
    }
  };

  return (
    <div>
      <h2>회원가입</h2>
      <form onSubmit={handleSignup}>
        <input
          type="text"
          placeholder="아이디"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="비밀번호 확인"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="닉네임"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="phonenumber"
          placeholder="전화번호"
          value={phoneNumber}
          onChange={(e) => setPhonenumber(e.target.value)}
          required
        />
        <button type="submit">확인</button>
      </form>
    </div>
  );
}

export default SignupPage;
