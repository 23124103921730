import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../api/AuthApi";
import { AuthContext } from "../context/AuthContext";
import "./LoginPage.css";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { handleLogin } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const { accessToken, user } = await login(username, password);
  
      handleLogin(user, accessToken);
  
      alert("로그인 성공");
      navigate("/");
    } catch (error) {
      alert("로그인 실패. 다시 시도해주세요.");
    }
  };

  return (
    <form onSubmit={handleLoginSubmit}>
      <h2>로그인</h2>
      <input
        type="text"
        placeholder="ID"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <button type="submit">로그인</button>
    </form>
  );
}

export default LoginPage;
