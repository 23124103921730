export const imageHandler = function () {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
  
    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("file", file);
  
      

      try {

        const accessToken = localStorage.getItem("accessToken"); // 토큰 저장 위치에 따라 수정 필요

        if (!accessToken) {
          console.error("No token found");
          return;
      }

        // 이미지 업로드 API 호출
        const response = await fetch("https://eb.hongfolio.shop/api/posts/upload", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`, // 토큰 추가
          },
          body: formData,
        });
  
        const data = await response.json();
  
        if (data.imageUrl) {
          const range = this.quill.getSelection(); // 현재 커서 위치 가져오기
          this.quill.insertEmbed(range.index, "image", data.imageUrl); // 이미지 삽입
        }
      } catch (error) {
        console.error("Image upload failed:", error);
      }
    };
  };
  
  // Quill 모듈 설정
  export const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image"],
      ],
      handlers: {
        image: imageHandler, // 이미지 핸들러 연결
      },
    },
  };
  