import React, { createContext, useState, useEffect } from "react";
import axios from "../api/Axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const storedUser = localStorage.getItem("userInfo");

    if (accessToken) {
      // 서버에서 토큰 검증 요청
      axios
        .get("/auth/validate-token", { headers: { Authorization: `Bearer ${accessToken}` } })
        .then(() => {
          setUserInfo(JSON.parse(storedUser));
          setIsLoggedIn(true);
        })
        .catch(() => {
          handleLogout(); // 토큰이 유효하지 않으면 로그아웃 처리
        });
    } else {
      handleLogout(); // 토큰이 없으면 로그아웃 처리
    }
  }, []);

  const handleLogin = (user, accessToken) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("userInfo", JSON.stringify(user));
    setUserInfo(user);
    setIsLoggedIn(true);
  };

  const refreshAccessToken = async () => {
    try {
      const { data } = await axios.post("/auth/refresh-token");
      localStorage.setItem("accessToken", data.accessToken);
      return data.accessToken;
    } catch (error) {
      console.error("Token refresh failed:", error);
      handleLogout();
    }
  };

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfo");
    setUserInfo(null);
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, userInfo, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
