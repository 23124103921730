import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { modules } from "../module/QuillModules";
import "./CreatePost.css";

function EditPost() {
  const { id } = useParams();
  const [post, setPost] = useState({ title: "", content: "" });
  const [file, setFile] = useState(null); // 추가: 파일 상태
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await axios.get(`https://eb.hongfolio.shop/api/posts/id/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setPost(response.data);
      } catch (error) {
        console.error("Failed to fetch post:", error);
        if (error.response && error.response.status === 401) {
          await handleTokenRefresh();
          fetchPost();
        }
      }
    };

    fetchPost();
  }, [id]);

  const handleTokenRefresh = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        alert("로그인이 필요합니다.");
        navigate("/login");
        return;
      }

      const response = await axios.post("https://eb.hongfolio.shop/api/auth/refresh", {
        refreshToken,
      });

      const { accessToken, refreshToken: newRefreshToken } = response.data;
      localStorage.setItem("accessToken", accessToken);
      if (newRefreshToken) {
        localStorage.setItem("refreshToken", newRefreshToken);
      }
    } catch (error) {
      console.error("토큰 갱신 실패:", error);
      alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      navigate("/login");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  };

  const handleContentChange = (value) => {
    setPost({ ...post, content: value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userInfo || userInfo.username !== post.author) {
      alert("수정 권한이 없습니다.");
      return;
    }

    try {
      const accessToken = localStorage.getItem("accessToken");
      const formData = new FormData();
      formData.append("post", new Blob([JSON.stringify(post)], { type: "application/json" }));
      if (file) {
        formData.append("file", file);
      }

      await axios.put(`https://eb.hongfolio.shop/api/posts/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      alert("게시글이 수정되었습니다.");
      navigate(`/posts/${id}`);
    } catch (error) {
      console.error("게시글 수정 중 오류 발생:", error);
      if (error.response && error.response.status === 401) {
        await handleTokenRefresh();
        handleSubmit();
      } else {
        alert("게시글 수정에 실패했습니다.");
      }
    }
  };

  if (!post.title && !post.content) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ maxWidth: "600px", margin: "50px auto" }}>
      <h2>게시글 수정</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          value={post.title}
          onChange={handleInputChange}
          placeholder="제목"
          required
          style={{ width: "100%", padding: "10px", margin: "10px 0" }}
        />
        <ReactQuill
          value={post.content}
          onChange={handleContentChange}
          modules={modules}
          placeholder="내용을 입력하세요..."
          className="react-quill-container"
          
        />
        <input
          type="file"
          onChange={handleFileChange}
          style={{ margin: "10px 0" }}
        />
        <button
          type="submit"
          style={{
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          게시글 수정
        </button>
      </form>
    </div>
  );
}

export default EditPost;
