import axios from "axios";

const api = axios.create({
  baseURL: "https://eb.hongfolio.shop/api",
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      try {
        const response = await api.post("/auth/refresh-token"); // RefreshToken으로 갱신
        const newAccessToken = response.data.accessToken;
        localStorage.setItem("accessToken", newAccessToken); // 새로운 Access Token 저장
        error.config.headers.Authorization = `Bearer ${newAccessToken}`; // 헤더 갱신
        return api(error.config); // 이전 요청 재시도
      } catch (refreshError) {
        console.error("Session expired, please log in again.");
        localStorage.clear();
        window.location.href = "/login"; // 로그인 페이지로 리다이렉트
      }
    }
    return Promise.reject(error);
  }
);

export default api;
